@tailwind base;
@tailwind components;
@tailwind utilities;

body {
}

.josef{
  font-family: 'Josefin Slab', serif;
}
.mukta{
  font-family: 'Mukta', sans-serif;
}

* {
  border: 2px;
  border-color: red;
}